<template>
  <div class="collapsable-item-container-wrapper">
    <div class="left-floating-buttons">
      <b-tooltip label="Déplacer vers le haut" position="is-left">
        <a class="auto-hide" @click="$emit('moveItemUp')">
          <i class="mdi mdi-arrow-up" />
        </a>
      </b-tooltip>
      <b-tooltip label="Déplacer vers le bas" position="is-left">
        <a class="auto-hide" @click="$emit('moveItemDown')">
          <i class="mdi mdi-arrow-down" />
        </a>
      </b-tooltip>
    </div>
    <b-collapse :open="false" class="card collapsable-arrayitem-container">
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button"
      >
        <a class="card-header-icon">
          <i :class="props.open ? 'mdi mdi-menu-down' : 'mdi mdi-menu-right'" />
        </a>
        <v-runtime-template
          v-if="itemContainerHeader !== undefined"
          :template="itemContainerHeader"
        />
        <p class="card-header-title" v-else-if="model && model.nom">
          {{ (index + 1 ) * 10}} - {{model.nom}}
        </p>
        <div class="card-remove-icon">
          <b-tooltip label="Supprimer" position="is-left">
            <a @click="$emit('removeItem')">
              <i class="mdi mdi-close" />
            </a>
          </b-tooltip>
        </div>
      </div>
      <div class="card-content">
        <div class="content" style="border: none; padding: 10px">
          <slot />
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import VRuntimeTemplate from 'v-runtime-template';

export default {
  components: {
    VRuntimeTemplate,
  },
  props: {
    model: {
      type: [Array, Object, String],
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    itemContainerHeader: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style scoped>
  .title { background: whitesmoke; }
  .content { border: 2px solid whitesmoke; }
  a { color: blue; text-decoration: underline; }
  .card-remove-icon {
    position: absolute;
    top: 4px;
    right: 10px;
  }
  .card-remove-icon a {
    color: var(--primary-color);
    padding-right: 5px;
    padding-left: 5px;
    transition: font-size 0.2s;
  }
  .card-content {
    padding: 0;
  }
  .auto-hide {
    opacity: 0;
    transition: opacity 0.2s;
    background: white;
    padding-top: 4px;
    padding-bottom: 8px;
  }
  .collapsable-arrayitem-container >>> .collapsable-item-container-wrapper:hover .card-remove-icon a {
    font-size: 120%;
  }
  .collapsable-item-container-wrapper:hover .auto-hide {
    opacity: 1;
  }
  .left-floating-buttons {
    position: absolute;
    left: -40px;
  }
</style>
<style>
.arrayitem-container .form-group {
  width: auto !important;
}
</style>
